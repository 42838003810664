// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_logo__2wnlS {\n  text-align: center;\n  margin: 10px;\n}", "",{"version":3,"sources":["webpack://src/components/HSideBar/style.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;AACJ","sourcesContent":[".logo{\n    text-align: center;\n    margin: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "style_logo__2wnlS"
};
export default ___CSS_LOADER_EXPORT___;
