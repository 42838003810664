import { Breadcrumb, Layout } from "antd";
import { Router, useLocation, Link } from "@reach/router";
import HSideBar from "../components/HSideBar";
import HHeader from "../components/HHeader";
import HFooter from "../components/HFooter";
import DashboardScene from "../scenes/dashboard";
import ProductionScene from "../scenes/promotion-tool/production";
import ComboScene from "../scenes/promotion-tool/combo";
import PromotionScene from "../scenes/promotion-tool/promotion";
import PrivateRoute from "./PrivateRoute";
import LoginScene from "../scenes/login";
import PageDetailScene from "../scenes/promotion-tool/pageDetail/index";
import AppURL from "./AppURL";
import React, { useState } from "react";
import PromotionGroupScene from "../scenes/promotion-tool/promotion-group";
import PromotionGroupDetailScene from "../scenes/promotion-tool/promotion-group-detail";
import PromotionReportScene from "../scenes/promotion-tool/report";
import AuthPageScene from "../scenes/auth";

const { Content } = Layout;

const LayoutAdmin = (props: any) => {
  const location = useLocation();
  const [collapse, setCollapse] = useState(false);

  const onCollapse = () => {
    setCollapse(!collapse);
  };

  const item = location.pathname.split("/")[1];
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <HSideBar collapse={collapse} onCollapse={onCollapse} />
      <Layout
        className="site-layout"
        style={{ marginLeft: collapse === true ? 80 : 250 }}
      >
        <HHeader />
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>
              <Link to={AppURL.home()}>Home</Link>
            </Breadcrumb.Item>
            {item ? (
              <Breadcrumb.Item>
                {item.charAt(0).toUpperCase() + item.slice(1)}
              </Breadcrumb.Item>
            ) : null}
          </Breadcrumb>
          <div>{props.children}</div>
        </Content>
        <HFooter />
      </Layout>
    </Layout>
  );
};

const AppRoute = (props: any) => {
  return (
    <React.Fragment>
      <Router>
        <LoginScene path={AppURL.login()} />
        <AuthPageScene path="auth" />
        <LayoutAdmin path="/">
          <PrivateRoute as={DashboardScene} path={AppURL.home()} />
          <PrivateRoute as={PromotionScene} path={AppURL.promotion()} />
          <PrivateRoute
            as={PromotionGroupScene}
            path={AppURL.promotionGroup()}
          />
          <PrivateRoute as={PromotionReportScene} path={"/promotion-report"} />
          <PrivateRoute
            as={PromotionGroupDetailScene}
            path={"/promotion-group/:id"}
          />
          <PrivateRoute as={ComboScene} path={AppURL.combo()} />
          <PrivateRoute as={ProductionScene} path={AppURL.production()} />
          <PrivateRoute as={PageDetailScene} path={`/:page/:id`} />
        </LayoutAdmin>
      </Router>
    </React.Fragment>
  );
};

export default AppRoute;
