import { Card, Form, Input, Button, message, Image, Divider } from "antd";
import { RouteComponentProps, useNavigate } from "@reach/router";
import { IUser } from "../../types/user.type";
import styles from "./style.module.scss";
import logoSVG from "../../assets/logo/logo.png";
import { apiLogin } from "../../services/user.api";
import useApp from "../../context/app.context";
import { useICANID } from "@icanid/icanid-sdk-react";

export default function LoginScene(_: RouteComponentProps) {
  const navigate = useNavigate();
  const { loginWithRedirect } = useICANID();
  const { dispatchAuth } = useApp();
  const onFinish = async (form: IUser) => {
    await apiLogin(form)
      .then((res) => {
        navigate("/");
        dispatchAuth({ type: "LOGIN" });
        localStorage.setItem("token", res.data.key);
        window.location.reload();
      })
      .catch((e) => {
        message.error({ content: e.data.non_field_errors[0] });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error({ content: errorInfo });
  };
  return (
    <div className={styles.wrapperLogin}>
      <div style={{ textAlign: "center" }}>
        <Image src={logoSVG} preview={false} width={200} />
      </div>
      <Card>
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          {}
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
        <Divider />
        <Button onClick={() => loginWithRedirect()}>LOGIN WITH ICANID</Button>
      </Card>
    </div>
  );
}
