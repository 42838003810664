export const enUS_Transaction = {
    "app.transaction.title": "List Transaction",
    "app.transaction.table.phone": 'Phone',
    "app.transaction.table.status": 'Status',
    "app.transaction.table.product": 'Product',
    "app.transaction.table.invoice": 'Invoice',
    "app.transaction.table.transaction_id": 'Transaction ID',
    "app.transaction.table.platform_payment": 'Platform Payment',
    "app.transaction.table.payment_type": 'Payment Type',
    "app.transaction.table.payment_method": 'Phone',
    "app.transaction.table.cost": 'Cost',
    "app.transaction.table.money": 'Money',
    "app.transaction.table.promotion": 'Promotion',
    "app.transaction.table.created_date": 'Created Date',
    "app.transaction.table.payment_date": 'Payment Date',
    "app.transaction.table.payment_error": 'Payment Error',

    "app.transaction.form.form_create_date": 'From Create Date',
    "app.transaction.form.to_create_date": 'To Create Date',
    "app.transaction.form.form_payment_date": 'From Payment Date',
    "app.transaction.form.to_payment_date": 'To Payment Date',
}