import React from "react";
import { RouteComponentProps } from "@reach/router";
import LoginScene from "../scenes/login";
import useApp from "../context/app.context";

interface PrivateRouteProps extends RouteComponentProps {
  as: React.ElementType<any>;
}

export default function PrivateRoute({as: Comp, ...props}: PrivateRouteProps) {
  const {
    stateAuth: { isAuthenticated },
  } = useApp();  return isAuthenticated ? <Comp {...props}/> : <LoginScene path="login"/>;
}
