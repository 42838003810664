import { IUserResult, Locale } from "../types/user.type";

export type AuthAction =
  | {
      type: "LOGIN";
    }
  | {
      type: "LOAD_USER";
      user: IUserResult;
    }
  | { type: "LOGOUT" }
  | {
      type: "GET_LOCALE";
      locale: Locale;
    }
  | {
      type: "SET_CHANELID";
      chanelId: string;
    };

export interface AuthState {
  isAuthenticated: boolean;
  locale: Locale;
  user: IUserResult | null;
  chanelId: string;
}

export const initialStateAuth: AuthState = {
  isAuthenticated: false,
  locale: "vi_VN",
  user: null,
  chanelId: "",
};

export function AuthReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case "LOGIN": {
      return { ...state, isAuthenticated: true };
    }
    case "LOAD_USER": {
      return { ...state, user: action.user };
    }
    case "LOGOUT": {
      return {
        isAuthenticated: false,
        locale: "vi_VN",
        user: null,
        chanelId: "",
      };
    }
    case "GET_LOCALE": {
      return { ...state, locale: action.locale };
    }
    case "SET_CHANELID": {
      return { ...state, chanelId: action.chanelId };
    }
    default:
      return state;
  }
}
