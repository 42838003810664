import { message } from "antd";
import { IProduction, IProductionParams } from "../types/production.type";
import { getLocalStorageValue } from "../utils/utils";
import API from "./request";

const header = {
  Authorization: `${getLocalStorageValue("token")}`,
};

export const apiListProduction = async (chanelId: string) => {
  const res = await API.get<IProduction[]>("/promotion/api/products/", {
    headers: {
      ...header,
      "x-api-channel-id": chanelId,
    },
  });
  return res.data;
};

export const apiCreateProduction = async (
  params: IProductionParams,
  chanelId: string
) => {
  await API.post<IProduction>("/promotion/api/products/", params, {
    headers: {
      ...header,
      "x-api-channel-id": chanelId,
    },
  });
};

export const apiDeleteProduction = async (id: string, chanelId: string) => {
  await API.delete(`/promotion/api/products/${id}/`, {
    headers: {
      ...header,
      "x-api-channel-id": chanelId,
    },
  })
    .then(() => {
      message.success("Delete success!");
    })
    .catch(() => {
      message.error("Error!");
    });
};

export const apiUpdateProduction = async (
  id: string | undefined,
  params: any,
  chanelId: string
) => {
  const res = await API.put(`/promotion/api/products/${id}/`, params, {
    headers: {
      ...header,
      "x-api-channel-id": chanelId,
    },
  });
  return res;
};
