import React from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import { Card, Col, Form, Input, Row, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useLocale } from "../../../locales";
import { apiDetail } from "../../../services/detail.api";
import { IProduction } from "../../../types/production.type";
import { apiListProduction } from "../../../services/production.api";
import useApp from "../../../context/app.context";

export default function PageDetailScene(_: RouteComponentProps) {
  const params = useParams();
  const { t } = useLocale();
  const {
    stateAuth: { chanelId },
  } = useApp();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [dataProduct, setDataProduct] = useState<IProduction[]>([]);

  const page = params.page;
  const id = params.id;

  const fetchListPage = useCallback(async () => {
    setLoading(true);
    const res: any = await apiDetail(page, id);
    if (res) {
      setData(res);
      setLoading(false);
    }
  }, [page, id]);

  const fetchListProducts = useCallback(async () => {
    setLoading(true);
    const res: IProduction[] = await apiListProduction(chanelId);
    if (res) {
      setDataProduct(res);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchListPage();
    fetchListProducts();
  }, [fetchListPage, fetchListProducts]);

  return (
    <Card loading={loading}>
      {data ? (
        <React.Fragment>
          <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Row gutter={12}>
              {data.name ? (
                <Col span={12}>
                  <Form.Item
                    label={t({ id: "app.promotion_tool.name" })}
                    name={data.name}
                    initialValue={data.name}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}
              {data.code ? (
                <Col span={12}>
                  <Form.Item
                    label={t({ id: "app.promotion_tool.code" })}
                    name={data.code}
                    initialValue={data.code}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.description ? (
                <Col span={12}>
                  <Form.Item
                    label={t({ id: "app.promotion_tool.description" })}
                    name={data.description}
                    initialValue={data.description}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.products ? (
                <Col span={12}>
                  <Form.Item
                    name="products"
                    label={t({ id: "app.promotion_tool.products" })}
                    rules={[{ required: true }]}
                    initialValue={data.products}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select a option and change Input"
                      allowClear
                      defaultValue={data.products}
                    >
                      {dataProduct.map((i, index) => (
                        <Select.Option value={i.id} key={index}>
                          {i.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : null}

              {data.amount_of_codes ? (
                <Col span={12}>
                  <Form.Item
                    label={t({
                      id: "app.promotion_tool.promotions.amount_of_codes",
                    })}
                    name={data.amount_of_codes}
                    initialValue={data.amount_of_codes}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.code_duration ? (
                <Col span={12}>
                  <Form.Item
                    label={t({
                      id: "app.promotion_tool.promotions.code_duration",
                    })}
                    name={data.code_duration}
                    initialValue={data.code_duration}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.discount_type ? (
                <Col span={12}>
                  <Form.Item
                    label={t({
                      id: "app.promotion_tool.promotions.discount_type",
                    })}
                    name={data.discount_type}
                    initialValue={data.discount_type === "PE" ? "%" : "Tiền"}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.discount_value ? (
                <Col span={12}>
                  <Form.Item
                    label={t({
                      id: "app.promotion_tool.promotions.discount_value",
                    })}
                    name={data.discount_value}
                    initialValue={data.discount_value}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.expected_date ? (
                <Col span={12}>
                  <Form.Item
                    label={t({
                      id: "app.promotion_tool.promotions.expected_date",
                    })}
                    name={data.expected_date}
                    initialValue={data.expected_date}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.expires_date ? (
                <Col span={12}>
                  <Form.Item
                    label={t({
                      id: "app.promotion_tool.promotions.expires_date",
                    })}
                    name={data.expires_date}
                    initialValue={data.expires_date}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.gift_type ? (
                <Col span={12}>
                  <Form.Item
                    label={t({ id: "app.promotion_tool.promotions.gift_type" })}
                    name={data.gift_type}
                    initialValue={data.gift_type === "PR" ? "Product" : "Combo"}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.max_flat_discount_value ? (
                <Col span={12}>
                  <Form.Item
                    label={t({
                      id: "app.promotion_tool.promotions.max_flat_discount_value",
                    })}
                    name={data.max_flat_discount_value}
                    initialValue={data.max_flat_discount_value}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.promotion_combo ? (
                <Col span={12}>
                  <Form.Item
                    label={t({
                      id: "app.promotion_tool.promotions.promotion_combo",
                    })}
                    name={data.promotion_combo}
                    initialValue={data.promotion_combo}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.promotion_product ? (
                <Col span={12}>
                  <Form.Item
                    label={t({
                      id: "app.promotion_tool.promotions.promotion_product",
                    })}
                    name={data.promotion_product}
                    initialValue={data.promotion_product}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.redemption_type ? (
                <Col span={12}>
                  <Form.Item
                    label={t({
                      id: "app.promotion_tool.promotions.redemption_type",
                    })}
                    name={data.redemption_type}
                    initialValue={
                      data.redemption_type === "CO" ? "Coupon" : "Voucher"
                    }
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.redemption_value ? (
                <Col span={12}>
                  <Form.Item
                    label={t({
                      id: "app.promotion_tool.promotions.redemption_value",
                    })}
                    name={data.redemption_value}
                    initialValue={
                      data.redemption_value === "DI" ? "Giảm giá" : "Quà tặng"
                    }
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.starts_date ? (
                <Col span={12}>
                  <Form.Item
                    label={t({
                      id: "app.promotion_tool.promotions.starts_date",
                    })}
                    name={data.starts_date}
                    initialValue={data.starts_date}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.state ? (
                <Col span={12}>
                  <Form.Item
                    label={t({ id: "app.promotion_tool.promotions.state" })}
                    name={data.state}
                    initialValue={data.state}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.status && (
                <Col span={12}>
                  <Form.Item
                    label={t({ id: "app.promotion_tool.promotions.status" })}
                    name={data.status}
                    // initialValue={data.status === "AP"? 1 : 2 }
                  >
                    {data.status === "AP" && (
                      <Input
                        disabled={params.page === "promotions" ? true : false}
                        value="Approve"
                      />
                    )}
                    {data.status === "SU" && (
                      <Input
                        disabled={params.page === "promotions" ? true : false}
                        value="Submit"
                      />
                    )}
                    {data.status === "CR" && (
                      <Input
                        disabled={params.page === "promotions" ? true : false}
                        value="Create"
                      />
                    )}
                  </Form.Item>
                </Col>
              )}

              {data.updated_reason ? (
                <Col span={12}>
                  <Form.Item
                    label={t({
                      id: "app.promotion_tool.promotions.updated_reason",
                    })}
                    name={data.updated_reason}
                    initialValue={data.updated_reason}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.updated_date ? (
                <Col span={12}>
                  <Form.Item
                    label={t({ id: "app.promotion_tool.updated_date" })}
                    name={data.updated_date}
                    initialValue={data.updated_date}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {data.created_date ? (
                <Col span={12}>
                  <Form.Item
                    label={t({ id: "app.promotion_tool.created_date" })}
                    name={data.created_date}
                    initialValue={data.created_date}
                  >
                    <Input
                      disabled={params.page === "promotions" ? true : false}
                    />
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
          </Form>
        </React.Fragment>
      ) : null}
    </Card>
  );
}
