import { Button, Card, Table, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useLocale } from "../../../locales";
import {
  //apiDeleteProduction,
  apiListProduction,
} from "../../../services/production.api";
import { IProduction } from "../../../types/production.type";
//import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import FormCreateProduct from "../../../components/HFormCreateProduct";
//import FormUpdateProduct from "../../../components/HFormUpdateProduct";
import { RouteComponentProps } from "@reach/router";
import { tableColumnTextFilterConfig } from "../../../utils/filterTable";
import useApp from "../../../context/app.context";

type Data = {
  key: string;
  name: string;
};

export default function ProductionScene(_: RouteComponentProps) {
  const { t } = useLocale();
  const {
    stateAuth: { chanelId },
  } = useApp();
  const [data, setData] = useState<IProduction[]>([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [isModalUpdate, setIsModalUpdate] = useState(false);
  //const [dataUpdate, setDataUpdate] = useState<IProduction>();

  const fetchListProduction = useCallback(async () => {
    setLoading(true);
    const res: IProduction[] = await apiListProduction(chanelId);
    if (res) {
      setData(res);
      setLoading(false);
      setIsModalVisible(false);
      // setIsModalUpdate(false);
    }
  }, []);

  useEffect(() => {
    fetchListProduction();
  }, [fetchListProduction]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const handleCancelUpdate = () => {
  //   setIsModalUpdate(false);
  // };

  // const deleteProduct = async (id: string) => {
  //   setLoading(true);
  //   await apiDeleteProduction(id).then(()=>{
  //     fetchListProduction();
  //   });
  //   setLoading(false);
  // };

  const columns: any = [
    {
      title: t({ id: "app.promotion_tool.products.name" }),
      key: "name",
      fixed: "left",
      render: (text: IProduction) => text.name,
      ...tableColumnTextFilterConfig<Data>(),
      onFilter: (value: any, record: any) => {
        return record.name
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
    {
      title: t({ id: "app.promotion_tool.products.code" }),
      dataIndex: "code",
      key: "code",
      fixed: "left",
      sorter: (a: any, b: any) => a.code - b.code,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t({ id: "app.promotion_tool.products.description" }),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t({ id: "app.promotion_tool.products.created_date" }),
      dataIndex: "created_date",
      key: "created_date",
    },
    {
      title: t({ id: "app.promotion_tool.products.updated_date" }),
      dataIndex: "updated_date",
      key: "updated_date",
    },
    // {
    //   title: t({ id: "app.promotion_tool.action" }),
    //   key: "action",
    //   render: (text: any, record: IProduction) => (
    //     <div>
    //       <Button
    //         type="primary"
    //         icon={<EditOutlined />}
    //         onClick={() => {
    //           setIsModalUpdate(!isModalUpdate);
    //           setDataUpdate(record);
    //         }}
    //       />
    //       <Button
    //         style={{ marginLeft: "10px" }}
    //         danger
    //         icon={<DeleteOutlined />}
    //         onClick={() => deleteProduct(record.id)}
    //       />
    //     </div>
    //   ),
    // },
  ];

  return (
    <div>
      <Card
        title={t({ id: "app.promotion_tool.products.title" })}
        extra={
          <Button
            type="primary"
            onClick={() => {
              setIsModalVisible(!isModalVisible);
            }}
          >
            {t({ id: "app.promotion_tool.add" })}
          </Button>
        }
      >
        {/* <Row gutter={16}>
          <Col span={12}>
            <span>Filter</span>
            <Input />
          </Col>
          <Col span={12}>
            <span>Filter on</span>
          </Col>
        </Row> */}
        <br />
        <Table
          loading={loading}
          scroll={{ x: 1500 }}
          dataSource={data}
          columns={columns}
        />
      </Card>
      <Modal
        title={t({ id: "app.promotion_tool.products.add_production" })}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        width={900}
      >
        <FormCreateProduct fetchData={fetchListProduction} />
      </Modal>

      {/* <Modal
        title={t({ id: "app.promotion_tool.products.add_production" })}
        visible={isModalUpdate}
        footer={null}
        onCancel={handleCancelUpdate}
      >
        <FormUpdateProduct fetchData={fetchListProduction} data={dataUpdate} />
      </Modal> */}
    </div>
  );
}
