import { message } from "antd";
import {
  IPromotion,
  IPromotionGroup,
  IPromotionGroupParams,
} from "../types/promotion.type";
import { getLocalStorageValue } from "../utils/utils";
import API from "./request";

const header = {
  Authorization: `${getLocalStorageValue("token")}`,
};

export const apiListPromotion = async (chanelId: string) => {
  const res = await API.get<IPromotion[]>("/promotion/api/promotions/", {
    headers: {
      ...header,
      "x-api-channel-id": chanelId,
    },
  });
  return res.data;
};

export const apiCreatePromotion = async (params: any, chanelId: string) => {
  const res = await API.post<IPromotion>("/promotion/api/promotions/", params, {
    headers: {
      ...header,
      "x-api-channel-id": chanelId,
    },
  });
  return res;
};

export const apiUpdatePromotion = async (
  id: string | undefined,
  params: any,
  chanelId: string
) => {
  const res = await API.put(`/promotion/api/promotions/${id}/`, params, {
    headers: {
      ...header,
      "x-api-channel-id": chanelId,
    },
  });
  return res;
};

export const apiDeletePromotion = async (id: string, chanelId: string) => {
  await API.delete(`/promotion/api/promotions/${id}/`, {
    headers: {
      ...header,
      "x-api-channel-id": chanelId,
    },
  })
    .then(() => {
      message.success({ content: "Delete Success !" });
    })
    .catch(() => {
      message.error({ content: "Error!" });
    });
};

export const apiSubmitPromotion = async (
  promotion_id: string,
  chanelId: string
) => {
  const res = await API.put(
    `/promotion/api/promotions/${promotion_id}/submit/`,
    null,
    {
      headers: {
        ...header,
        "x-api-channel-id": chanelId,
      },
    }
  );
  return res;
};

export const apiApprovePromotion = async (
  promotion_id: string,
  params: any,
  chanelId: string
) => {
  const res = await API.put(
    `promotion/api/promotions/${promotion_id}/approve/`,
    params,
    {
      headers: {
        ...header,
        "x-api-channel-id": chanelId,
      },
    }
  );
  return res;
};

export const apiGenCodePromotion = async (
  promotion_id: string,
  chanelId: string
) => {
  const res = await API.put(
    `promotion/api/promotions/${promotion_id}/gen_code/`,
    null,
    {
      headers: {
        ...header,
        "x-api-channel-id": chanelId,
      },
    }
  );
  return res;
};

export const apiPickCodePromotion = async (
  promotion_id: string,
  params: any,
  chanelId: string
) => {
  const res = await API.put(
    `promotion/api/promotions/${promotion_id}/pick_code/`,
    params,
    {
      headers: {
        ...header,
        "x-api-channel-id": chanelId,
      },
    }
  );
  return res;
};

export const apiCreatePromotionGroups = async (
  params: IPromotionGroupParams,
  chanelId: string
) => {
  const res = await API.post("/promotion/api/promotiongroups/", params, {
    headers: {
      ...header,
      "x-api-channel-id": chanelId,
    },
  });
  return res;
};

export const apiUpdatePromotionGroups = async (
  params: IPromotionGroupParams,
  id: string,
  chanelId: string
) => {
  const res = await API.put(`/promotion/api/promotiongroups/${id}/`, params, {
    headers: {
      ...header,
      "x-api-channel-id": chanelId,
    },
  });
  return res;
};

export const apiListPromotionGroups = async (chanelId: string) => {
  const res = await API.get<IPromotionGroup[]>(
    "/promotion/api/promotiongroups/",
    {
      headers: {
        ...header,
        "x-api-channel-id": chanelId,
      },
    }
  );
  return res.data;
};

export const apiDetailPromotionGroups = async (
  id: string,
  chanelId: string
) => {
  const res = await API.get<IPromotionGroup>(
    `/promotion/api/promotiongroups/${id}/`,
    {
      headers: {
        ...header,
        "x-api-channel-id": chanelId,
      },
    }
  );
  return res.data;
};

export const apiDelPromotionGroups = async (id: string, chanelId: string) => {
  const res = await API.delete<IPromotionGroup>(
    `/promotion/api/promotiongroups/${id}/`,
    {
      headers: {
        ...header,
        "x-api-channel-id": chanelId,
      },
    }
  );
  return res;
};

export const apiListReportPromotion = async (
  limit: number,
  skip: number,
  chanelId: string
) => {
  const res = await API.get("/promotion/api/promotions/report/", {
    params: { limit, skip },
    headers: {
      ...header,
      "x-api-channel-id": chanelId,
    },
  });
  return res.data;
};

export const apiReportPromotion = async (
  data: object,
  promotion_ids: string,
  chanelId: string
) => {
  await API.post("/promotion/api/promotions/send_report/", data, {
    params: { promotion_ids },
    headers: {
      ...header,
      "x-api-channel-id": chanelId,
    },
  });
};
