class BaseConfig {
  isDev() {
    return process.env.NODE_ENV === "development";
  }
}

class ConfigDEV extends BaseConfig {
  API_URL = "https://api.dev.icankids.com.vn";
  API_BUS = "https://api-id.dev.icanx.vn";
  DOMAIN_ID = "https://im-id.icanx.vn";
  REDIRECT_URI = "https://promotion.dev.icankids.com.vn/auth";
  CLIENTID = "lyt-business_client1";
  CLIENTSECRET = "lyt-business-396d9cb4-33a7-42c2-9161-291da2bd2cc7-dev";
}

class ConfigPROD extends BaseConfig {
  API_URL = "https://api.dev.icankids.com.vn";
  API_BUS = "https://api-id.dev.icanx.vn";
  DOMAIN_ID = "https://im-id.icanx.vn";
  REDIRECT_URI = "http://localhost:3001/auth";
  CLIENTID = "lyt-business_client1";
  CLIENTSECRET = "lyt-business-396d9cb4-33a7-42c2-9161-291da2bd2cc7-dev";
}

const Config =
  process.env.NODE_ENV === "development" ? new ConfigDEV() : new ConfigPROD();
// const config = process.env.NODE_ENV === 'development' ? new ConfigSTAG() : new ConfigPROD();

export default Config;
