import { Button, Form, FormInstance, Input, message } from "antd";
import React, { FC } from "react";
import useApp from "../../context/app.context";
import { useLocale } from "../../locales";
import { apiCreateProduction } from "../../services/production.api";

type ProductionCreateProps = {
  fetchData: () => void;
};

const FormCreateProduct: FC<ProductionCreateProps> = (props) => {
  const { t } = useLocale();
  const {
    stateAuth: { chanelId },
  } = useApp();
  const formRef = React.createRef<FormInstance>();
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    formRef.current?.resetFields();
    await apiCreateProduction(values, chanelId)
      .then(() => {
        message.success(t({ id: "app.promotion_tool.success" }));
        form.resetFields();
        props.fetchData();
      })
      .catch(() => {
        message.error(t({ id: "app.promotion_tool.error" }));
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div
        style={{
          padding: 6,
          backgroundColor: "#0057A5",
          marginBottom: 20,
          color: "white",
          fontSize: 16,
        }}
      >
        {t({ id: "app.promotion_tool.products.info" })}
      </div>
      <Form
        name="basic"
        layout="horizontal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        preserve={false}
        ref={formRef}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label={t({ id: "app.promotion_tool.products.code" })}
          name="code"
          rules={[
            {
              required: true,
              message: t({ id: "app.promotion_tool.error_form" }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t({ id: "app.promotion_tool.products.name" })}
          name="name"
          rules={[
            {
              required: true,
              message: t({ id: "app.promotion_tool.error_form" }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t({ id: "app.promotion_tool.products.description" })}
          name="description"
          rules={[
            {
              required: true,
              message: t({ id: "app.promotion_tool.error_form" }),
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 11, span: 24 }}>
          <Button type="primary" htmlType="submit">
            {t({ id: "app.promotion_tool.add" })}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default FormCreateProduct;
