export const viVN_Promotion = {
  "app.promotion_tool.promotions.title": "Danh sách khuyến mãi",
  "app.promotion_tool.promotions.code": "Mã chương trình",
  "app.promotion_tool.promotions.name": "Tên chương trình",
  "app.promotion_tool.promotions.id_product": "Mã sản phẩm",
  "app.promotion_tool.promotions.type_promotion": "Loại CTKM",
  "app.promotion_tool.promotions.redemption_type": "Hình thức khuyến mại",
  "app.promotion_tool.promotions.expires_date": "Ngày kết thúc",
  "app.promotion_tool.promotions.expires_hour": "Giờ kết thúc",
  "app.promotion_tool.promotions.expected_date": "Ngày dự kiến",
  "app.promotion_tool.promotions.expected_hour": "Giờ dự kiến",
  "app.promotion_tool.promotions.discount_type": "Hình thức giảm giá",
  "app.promotion_tool.promotions.redemption_value": "Giá trị khuyến mại",
  "app.promotion_tool.promotions.gift_type": "Hình thức quà",
  "app.promotion_tool.promotions.code_duration": "Thời gian sử dụng mã",
  "app.promotion_tool.promotions.description": "Miêu tả",
  "app.promotion_tool.promotions.amount_of_codes": "Số lượng mã",
  "app.promotion_tool.promotions.max_flat_discount_value":
    "Giá trị chiết khấu tối đa",
  "app.promotion_tool.promotions.flat_discount_value": "Giá trị chiết khấu",
  "app.promotion_tool.promotions.status": "Trạng thái",
  "app.promotion_tool.promotions.modal.title": "Thêm chương trình khuyến mại",
  "app.promotion_tool.promotions.modalUpdate.title":
    "Cập nhật chương trình khuyến mại",
  "app.promotion_tool.promotions.discount_value": "Giá trị chiết khấu",
  "app.promotion_tool.promotions.promotion_combo": "Gói khuyến mãi",
  "app.promotion_tool.promotions.promotion_product": "Sản phẩm khuyến mãi",
  "app.promotion_tool.promotions.starts_date": "Ngày bắt đầu",
  "app.promotion_tool.promotions.state": "Tình trạng",
  "app.promotion_tool.promotions.updated_reason": "Cập nhật lý do",
  "app.promotion_tool.promotions.internal": "Nội bộ",

  "app.promotion_tool.form_create.promo_info": "Thông tin khuyến mại",
  "app.promotion_tool.form_create.promo_value": "Giá trị khuyến mại",
  "app.promotion_tool.form_create.setup_code_promo": "Thiết lập mã khuyến mại",
  "app.promotion_tool.form_create.setup_date": "Thiết lập ngày",

  "app.promotion_tool.action": "Hành động",
  "app.promotion_tool.search": "Tìm kiếm",
  "app.promotion_tool.name": "Tên",
  "app.promotion_tool.code": "Mã",
  "app.promotion_tool.description": "Miêu tả",
  "app.promotion_tool.created_date": "Ngày tạo",
  "app.promotion_tool.updated_date": "Ngày cập nhật",
  "app.promotion_tool.products": "Sản phẩm",
  "app.promotion_tool.add": "Thêm",
  "app.promotion_tool.error_form": "Vui lòng điền vào ô trống",
  "app.promotion_tool.success": "thành công",
  "app.promotion_tool.error": "Thêm thất bại",

  "app.promotion_tool.products.title": "Danh sách sản phẩm",
  "app.promotion_tool.products.add_production": "Thêm sản phẩm",
  "app.promotion_tool.products.code": "Mã sản phẩm",
  "app.promotion_tool.products.name": "Tên sản phẩm",
  "app.promotion_tool.products.description": "Miêu tả",
  "app.promotion_tool.products.created_date": "Ngày tạo",
  "app.promotion_tool.products.updated_date": "Ngày cập nhật",
  "app.promotion_tool.products.info": " Thông tin Production",

  "app.promotion_tool.combo.title": "Danh sách gói",
  "app.promotion_tool.combo.add_combo": "Thêm gói",
  "app.promotion_tool.combos.info": "Thông tin Combo ",

  "app.promotion_tool.combos.code": "Mã sản phẩm",
  "app.promotion_tool.combos.name": "Tên sản phẩm",
  "app.promotion_tool.promotions.promotion_per_user_usage_limit":
    "Giới hạn người sử dụng",
  "app.promotion_tool.promotions.promotion_usage_limit_product":
    "Giới hạn sử dụng sản phẩm",
  "app.promotion_tool.promotions.offer": "Tặng",
  "app.promotion_tool.promotions.redemption_component": "Hình thức khuyến mại",
  "app.promotion_tool.promotions.code_prefix": "Tiền tố",
  "app.promotion_tool.promotions.code_pattern": "Giữa",
  "app.promotion_tool.promotions.code_postfix": "Hậu tố",
  "app.promotion_tool.promotions.code_enerates_date": "Ngày mã hoá",

  "app.promotion_tool.promotions.promotion_product_type": "Loại sản phẩm",
  "app.promotion_tool.promotions.type": "Loại",

  "app.promotion_tool.promotions_group.title": "Nhóm khuyến mãi",
  "app.promotion_tool.promotions_group.add_promotion_group":
    "Thêm nhóm khuyến mãi",
};
