export const enUS_sideBar = {
    'app.sidebar.dashboard': 'Dashboard',
    'app.sidebar.transaction': 'Transaction',
    'app.sidebar.manage_sms': 'Manage SMS',
    'app.sidebar.promotion_tool': 'Promotion Tool',
    'app.sidebar.promotion_tool.promotion': 'Promotion',
    'app.sidebar.promotion_tool.promotion_group': 'Promotion Group',
    'app.sidebar.promotion_tool.combo': 'Combo',
    'app.sidebar.promotion_tool.production': 'Production',
    'app.sidebar.manage_sms.send_sms': 'Send SMS',
    'app.sidebar.manage_sms.manage_campaign': 'Manage Campaign',
    'app.sidebar.manage_sms.manage_sms_sample': 'Manage SMS Sample',
    'app.sidebar.manage_sms.flow_sms': 'Flow SMS',
    'app.sidebar.manage_sms.change_phone_number': 'Change Phone Number',
    'app.sidebar.manage_sms.check_phone_number': 'Check Phone Number',
    'app.sidebar.manage_sms.sms_toast': 'SMS Toast',
    'app.sidebar.manage_sms.report_sms': 'SMS Report',
    'app.sidebar.manage_sms.report_daily': 'Daily Report',
    'app.sidebar.manage_sms.report_monthly': 'Monthly Report',
    'app.sidebar.manage_sms.report_campain': 'Campain Report',
    'app.sidebar.manage_sms.export_sms': 'Export SMS',
  };