import { Button, Card, Table, Modal, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import { useLocale } from "../../../locales";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { tableColumnTextFilterConfig } from "../../../utils/filterTable";
import {
  apiDelPromotionGroups,
  apiListPromotionGroups,
} from "../../../services/promotion.api";
import { IPromotionGroup } from "../../../types/promotion.type";
import FormCreatePromotionGroup from "../../../components/HFormCreatePromotionGroup";
import useApp from "../../../context/app.context";

type Data = {
  key: string;
  name: string;
};

export default function PromotionGroupScene(_: RouteComponentProps) {
  const { t } = useLocale();
  const {
    stateAuth: { chanelId },
  } = useApp();
  const [data, setData] = useState<IPromotionGroup[]>([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalCode, setIsModalCode] = useState(false);
  const [idPromo, setIdPromo] = useState<any>();

  const fetchListPromotionGroup = useCallback(async () => {
    setLoading(true);
    const res: IPromotionGroup[] = await apiListPromotionGroups(chanelId);
    setData(res);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchListPromotionGroup();
  }, [fetchListPromotionGroup]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const deletePromotionGroup = async (id: string) => {
    const res: any = await apiDelPromotionGroups(id, chanelId);
    if (res.status === 204) {
      message.success("Delete success!");
      fetchListPromotionGroup();
    } else {
      message.error({ content: res.data.promotions[0] });
    }
  };

  const columns: any = [
    {
      fixed: "left",
      title: "Promotion name",
      key: "name",
      render: (text: IPromotionGroup) => (
        <Link to={`/promotion-group/${text.id}`}>{text.name}</Link>
      ),
      ...tableColumnTextFilterConfig<Data>(),
      onFilter: (value: any, record: IPromotionGroup) => {
        return record.name
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
    },
    {
      title: "Code Promotion",
      key: "code_promotion",
      render: (record: IPromotionGroup) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setIsModalCode(true);
              setIdPromo(record.promotions);
            }}
          >
            show code
          </Button>
        </>
      ),
    },
    {
      title: "Created date",
      key: "created_date",
      render: (record: IPromotionGroup) =>
        moment(record.created_date).format("L"),
    },
    {
      title: "Updated date",
      key: "updated_date",
      render: (record: IPromotionGroup) =>
        moment(record.updated_date).format("L"),
    },
    {
      title: "Action",
      key: "action",
      render: (record: IPromotionGroup) => (
        <>
          <Button
            href={`/promotion-group/${record.id}`}
            type="link"
            icon={<EditOutlined />}
          />
          <Button
            style={{ marginLeft: 10, marginRight: 10 }}
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              deletePromotionGroup(record.id);
            }}
          />
        </>
      ),
    },
  ];
  return (
    <div>
      <Card
        title={t({ id: "app.promotion_tool.promotions_group.title" })}
        extra={
          <Button
            type="primary"
            onClick={() => {
              setIsModalVisible(!isModalVisible);
            }}
          >
            {t({ id: "app.promotion_tool.add" })}
          </Button>
        }
      >
        <Table
          loading={loading}
          scroll={{ x: 1500 }}
          dataSource={data}
          columns={columns}
        />
      </Card>
      <Modal
        title={t({
          id: "app.promotion_tool.promotions_group.add_promotion_group",
        })}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        width={900}
      >
        <FormCreatePromotionGroup
          fetchData={fetchListPromotionGroup}
          offModal={() => setIsModalVisible(false)}
        />
      </Modal>

      {idPromo && (
        <Modal
          visible={isModalCode}
          onCancel={() => setIsModalCode(false)}
          footer={null}
        >
          {idPromo.map((i: any, index: any) => (
            <div key={index}>{i}</div>
          ))}
        </Modal>
      )}
    </div>
  );
}
