import { FC, useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Form,
  Select,
  InputNumber,
  message,
} from "antd";
import { IPromotion } from "../../types/promotion.type";
import { IProduction } from "../../types/production.type";
import { ICombo } from "../../types/combo.type";
import { apiListCombo } from "../../services/combo.api";
import { apiListProduction } from "../../services/production.api";
import { apiUpdatePromotion } from "../../services/promotion.api";
import { useLocale } from "../../locales";
import moment from "moment";
import useApp from "../../context/app.context";

type PromotionCreateProps = {
  fetchData: () => void;
  data: IPromotion | undefined;
};

const dateFormat = "YYYY-MM-DD";

const FormUpdatePromotion: FC<PromotionCreateProps> = (props) => {
  const { t } = useLocale();
  const {
    stateAuth: { chanelId },
  } = useApp();
  const [forms, setForms] = useState<Partial<IPromotion>>({});
  const [dataProduct, setDataProduct] = useState<IProduction[]>([]);
  const [dataCombo, setDataCombo] = useState<ICombo[]>([]);

  const fetchListCombo = useCallback(async () => {
    const res: ICombo[] = await apiListCombo();
    if (res) {
      setDataCombo(res);
    }
  }, []);

  const fetchListProduction = useCallback(async () => {
    const res: IProduction[] = await apiListProduction(chanelId);
    if (res) {
      setDataProduct(res);
    }
  }, []);

  useEffect(() => {
    fetchListProduction();
    fetchListCombo();
  }, [fetchListProduction, fetchListCombo]);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values: any) => {
    const res = await apiUpdatePromotion(
      props.data?.id,
      {
        kind: "NO",
        internal: true,
        ...values,
      },
      chanelId
    );
    if (res.status === 200) {
      message.success(t({ id: "app.promotion_tool.success" }));
      props.fetchData();
    } else {
      message.error(t({ id: "app.promotion_tool.error" }));
    }
  };
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t({ id: "app.promotion_tool.promotions.code" })}
            name="code"
            initialValue={props.data?.code}
            rules={[
              {
                required: true,
                message: t({ id: "app.promotion_tool.error_form" }),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t({ id: "app.promotion_tool.promotions.name" })}
            name="name"
            initialValue={props.data?.name}
            rules={[
              {
                required: true,
                message: t({ id: "app.promotion_tool.error_form" }),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t({ id: "app.promotion_tool.promotions.type_promotion" })}
            name="redemption_type"
            initialValue={props.data?.redemption_type}
            rules={[
              {
                required: true,
                message: t({ id: "app.promotion_tool.error_form" }),
              },
            ]}
          >
            <Select>
              <Select.Option value="CO">coupon</Select.Option>
              <Select.Option value="VO">voucher</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t({
              id: "app.promotion_tool.promotions.redemption_type",
            })}
            name="redemption_value"
            initialValue={props.data?.redemption_value}
            rules={[
              {
                required: true,
                message: t({ id: "app.promotion_tool.error_form" }),
              },
            ]}
          >
            <Select
              onChange={(value: string) =>
                setForms({ ...forms, redemption_value: value })
              }
            >
              <Select.Option value="DI">Giảm giá</Select.Option>
              <Select.Option value="GI">Quà tặng</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        {forms.redemption_value === "GI" ? (
          <>
            <Col span={12}>
              <Form.Item
                label={"Gift"}
                name="gift_type"
                initialValue={props.data?.gift_type}
                rules={[
                  {
                    required: true,
                    message: t({ id: "app.promotion_tool.error_form" }),
                  },
                ]}
              >
                <Select
                  onChange={(value: string) => {
                    setForms({ ...forms, gift_type: value });
                  }}
                >
                  <Select.Option value="PR">Product</Select.Option>
                  <Select.Option value="CO">Combo</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {forms.gift_type === "PR" ? (
              <Col span={12}>
                <Form.Item
                  label={"promotion_product"}
                  name="promotion_product"
                  initialValue={props.data?.promotion_product}
                  rules={[
                    {
                      required: true,
                      message: t({ id: "app.promotion_tool.error_form" }),
                    },
                  ]}
                >
                  <Select allowClear>
                    {dataProduct.map((i, index) => (
                      <Select.Option value={i.id} key={index}>
                        {i.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : null}

            {forms.gift_type === "CO" ? (
              <Col span={12}>
                <Form.Item
                  label={"promotion_combo"}
                  name="promotion_combo"
                  initialValue={props.data?.promotion_combo}
                  rules={[
                    {
                      required: true,
                      message: t({ id: "app.promotion_tool.error_form" }),
                    },
                  ]}
                >
                  <Select allowClear>
                    {dataCombo.map((i, index) => (
                      <Select.Option value={i.id} key={index}>
                        {i.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : null}
          </>
        ) : null}
        {forms.redemption_value === "DI" ? (
          <>
            <Col span={12}>
              <Form.Item
                label={"Discount type"}
                name="discount_type"
                initialValue={props.data?.discount_type}
                rules={[
                  {
                    required: true,
                    message: t({ id: "app.promotion_tool.error_form" }),
                  },
                ]}
              >
                <Select>
                  <Select.Option value="FL">Tiền</Select.Option>
                  <Select.Option value="PE">%</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={"promotion_product"}
                name="promotion_product"
                initialValue={props.data?.promotion_product}
                rules={[
                  {
                    required: true,
                    message: t({ id: "app.promotion_tool.error_form" }),
                  },
                ]}
              >
                <Select allowClear>
                  {dataProduct.map((i, index) => (
                    <Select.Option value={i.id} key={index}>
                      {i.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t({
                  id: "app.promotion_tool.promotions.max_flat_discount_value",
                })}
                name="max_flat_discount_value"
                initialValue={props.data?.max_flat_discount_value}
                rules={[
                  {
                    required: true,
                    message: t({ id: "app.promotion_tool.error_form" }),
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t({
                  id: "app.promotion_tool.promotions.flat_discount_value",
                })}
                name="flat_discount_value"
                initialValue={props.data?.flat_discount_value}
                rules={[
                  {
                    required: true,
                    message: t({ id: "app.promotion_tool.error_form" }),
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
          </>
        ) : null}
        <Col span={12}>
          <Form.Item
            label={t({ id: "app.promotion_tool.promotions.code_duration" })}
            name="code_duration"
            initialValue={
              props.data?.code_duration === -1 ? "" : props.data?.code_duration
            }
            rules={[
              {
                required: true,
                message: t({ id: "app.promotion_tool.error_form" }),
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t({
              id: "app.promotion_tool.promotions.amount_of_codes",
            })}
            name="amount_of_codes"
            initialValue={
              props.data?.amount_of_codes === -1
                ? ""
                : props.data?.amount_of_codes
            }
            rules={[
              {
                required: true,
                message: t({ id: "app.promotion_tool.error_form" }),
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item
            label={t({ id: "app.promotion_tool.promotions.expected_date" })}
            name="expected_date"
            initialValue={moment(props.data?.expected_date, dateFormat)}
            rules={[
              {
                required: true,
                message: t({ id: "app.promotion_tool.error_form" }),
              },
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={t({ id: "app.promotion_tool.promotions.expires_date" })}
            name="expires_date"
            initialValue={moment(props.data?.expires_date, dateFormat)}
            rules={[
              {
                required: true,
                message: t({ id: "app.promotion_tool.error_form" }),
              },
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={t({ id: "app.promotion_tool.promotions.expected_hour" })}
            name="expected_hour"
            initialValue={moment(props.data?.expected_hour, dateFormat)}
            rules={[
              {
                required: true,
                message: t({ id: "app.promotion_tool.error_form" }),
              },
            ]}
          >
            <DatePicker picker={"time"} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={t({ id: "app.promotion_tool.promotions.expires_hour" })}
            name="expires_hour"
            initialValue={moment(props.data?.expires_hour, dateFormat)}
            rules={[
              {
                required: true,
                message: t({ id: "app.promotion_tool.error_form" }),
              },
            ]}
          >
            <DatePicker picker={"time"} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          {t({ id: "app.promotion_tool.add" })}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormUpdatePromotion;
