import { ICombo, IComboCreate } from "../types/combo.type";
import { getLocalStorageValue } from "../utils/utils";
import API from "./request";

const header = {
  Authorization: `${getLocalStorageValue("token")}`,
};

export const apiListCombo = async () => {
  const res = await API.get<ICombo[]>("/promotion/api/combos/", {
    headers: header,
  });
  return res.data;
};

export const apiCreateCombo = async (params: IComboCreate) => {
  const res = await API.post<ICombo>("/promotion/api/combos/", params, {
    headers: header,
  });
  return res;
};

export const apiUpdateCombo = (
  id: string | undefined,
  params: IComboCreate
) => {
  const res = API.put<ICombo>(`/promotion/api/combos/${id}/`, params, {
    headers: header,
  });
  return res;
};

export const apiDeleteCombo = async (id: string) => {
  const res = await API.delete<ICombo>(`/promotion/api/combos/${id}/`, {
    headers: header,
  });
  return res;
};
