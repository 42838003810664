import { Button, Card, Form, Input, Select, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import { useLocale } from "../../../locales";
import {
  apiDetailPromotionGroups,
  apiListPromotion,
  apiUpdatePromotionGroups,
} from "../../../services/promotion.api";
import { IPromotion, IPromotionGroup } from "../../../types/promotion.type";
import useApp from "../../../context/app.context";

export default function PromotionGroupDetailScene(_: RouteComponentProps) {
  const { t } = useLocale();
  const {
    stateAuth: { chanelId },
  } = useApp();
  const params = useParams();
  const [data, setData] = useState<IPromotionGroup>();
  const [dataPromotion, setDataPromotion] = useState<IPromotion[]>([]);

  const fetchListProduction = useCallback(async () => {
    const res: IPromotion[] = await apiListPromotion(chanelId);
    if (res) {
      setDataPromotion(res);
    }
  }, []);

  const fetchDataDetail = useCallback(async () => {
    const id = params.id;
    const res = await apiDetailPromotionGroups(id, chanelId);
    if (res) {
      setData(res);
    }
  }, []);

  useEffect(() => {
    fetchDataDetail();
    fetchListProduction();
  }, [fetchListProduction, fetchDataDetail]);

  const onFinish = async (values: any) => {
    const id = params.id;
    const res = await apiUpdatePromotionGroups(values, id, chanelId);
    console.log(res);
    if (res.status === 200) {
      message.success(t({ id: "app.promotion_tool.success" }));
      fetchDataDetail();
    } else {
      message.error(t({ id: "app.promotion_tool.error" }));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Card title={"Update promotion group"}>
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        preserve={false}
      >
        {data && (
          <Form.Item
            label={"Name"}
            name="name"
            initialValue={data?.name}
            rules={[
              {
                required: true,
                message: t({ id: "app.promotion_tool.error_form" }),
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}
        {data && (
          <Form.Item
            label={"Description"}
            name="description"
            initialValue={data?.description}
            rules={[
              {
                required: true,
                message: t({ id: "app.promotion_tool.error_form" }),
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}

        {data && (
          <Form.Item
            name="promotions"
            label="Promotions"
            initialValue={data?.promotions}
          >
            <Select
              mode="multiple"
              placeholder="Select a option and change input text above"
              allowClear
            >
              {dataPromotion.map((i, index) => (
                <Select.Option value={i.id} key={index}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
