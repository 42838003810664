import { FC } from "react";
import { GiftOutlined, GoldOutlined, ShopOutlined } from "@ant-design/icons";
import { Link, useLocation } from "@reach/router";
import { Image, Layout, Menu } from "antd";
import style from "./style.module.scss";
import { ISideBarProps } from "./index.type";
import AppURL from "../../routes/AppURL";
import { useLocale } from "../../locales";
import logoSVG from "../../assets/logo/logo.png";
import iconPNG from "../../assets/logo/favicon.png";

const { Sider } = Layout;
const { SubMenu } = Menu;

type SidebarProps = {
  collapse: boolean;
  onCollapse: () => void;
};

const HSideBar: FC<SidebarProps> = (props) => {
  const { t } = useLocale();
  const location = useLocation();

  const key: string = location.pathname.split("/")[1];
  const menuItems: ISideBarProps[] = [
    {
      key: "promo",
      label: t({ id: "app.sidebar.promotion_tool.promotion" }),
      // url: AppURL.promotion(),
      icon: <GiftOutlined />,
      children: [
        {
          key: "promotion",
          label: t({ id: "app.sidebar.promotion_tool.promotion" }),
          url: AppURL.promotion(),
          icon: <GiftOutlined />,
        },
        {
          key: "promotion-group",
          label: t({ id: "app.sidebar.promotion_tool.promotion_group" }),
          url: AppURL.promotionGroup(),
          icon: <GiftOutlined />,
        },
        {
          key: "promotion-report",
          label: "Promotion Report",
          url: "/promotion-report",
          icon: <GiftOutlined />,
        },
      ],
    },
    {
      key: "combo",
      label: t({ id: "app.sidebar.promotion_tool.combo" }),
      url: AppURL.combo(),
      icon: <GoldOutlined />,
    },
    {
      key: "production",
      label: t({ id: "app.sidebar.promotion_tool.production" }),
      url: AppURL.production(),
      icon: <ShopOutlined />,
    },
  ];
  return (
    <Sider
      collapsible
      collapsed={props.collapse}
      width={250}
      onCollapse={props.onCollapse}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      <div className={style.logo}>
        {props.collapse ? (
          <Image src={iconPNG} preview={false} width={40} />
        ) : (
          <Image src={logoSVG} preview={false} width={170} />
        )}
      </div>
      <Menu theme="dark" defaultSelectedKeys={[key]} mode="inline">
        {menuItems.map((i) =>
          i.children ? (
            <SubMenu key={i.key} icon={i.icon} title={i.label}>
              {i.children.map((j) => (
                <Menu.Item key={j.key} icon={j.icon}>
                  <Link to={j.url}>{j.label}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={i.key} icon={i.icon}>
              <Link to={i.url || ""}>{i.label}</Link>
            </Menu.Item>
          )
        )}
      </Menu>
    </Sider>
  );
};

export default HSideBar;
