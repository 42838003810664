import React, { FC, useCallback, useEffect, useState } from "react";
import { Form, Input, Select, Button, message } from "antd";
import { useLocale } from "../../locales";
import { IPromotion } from "../../types/promotion.type";
import {
  apiCreatePromotionGroups,
  apiListPromotion,
} from "../../services/promotion.api";
import { FormInstance } from "antd/es/form";
import useApp from "../../context/app.context";

type PromotionGroupCreateProps = {
  fetchData: () => void;
  offModal: () => void;
};

const FormCreatePromotionGroup: FC<PromotionGroupCreateProps> = (props) => {
  const { t } = useLocale();
  const {
    stateAuth: { chanelId },
  } = useApp();
  const formRef = React.createRef<FormInstance>();
  const [dataPromotion, setDataPromotion] = useState<IPromotion[]>([]);

  const fetchListProduction = useCallback(async () => {
    const res: IPromotion[] = await apiListPromotion(chanelId);
    if (res) {
      setDataPromotion(res);
    }
  }, []);

  useEffect(() => {
    fetchListProduction();
  }, [fetchListProduction]);

  const onFinish = async (values: any) => {
    const res = await apiCreatePromotionGroups(values, chanelId);
    if (res.status === 201) {
      message.success(t({ id: "app.promotion_tool.success" }));
      props.fetchData();
      props.offModal();
      formRef.current?.resetFields();
    } else {
      message.error(t({ id: "app.promotion_tool.error" }));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div
        style={{
          padding: 6,
          backgroundColor: "#0057A5",
          marginBottom: 20,
          color: "white",
          fontSize: 16,
        }}
      >
        Thông tin nhóm khuyến mại
      </div>
      <Form
        name="basic"
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        preserve={false}
        ref={formRef}
      >
        <Form.Item
          label={t({ id: "app.promotion_tool.name" })}
          name="name"
          rules={[
            {
              required: true,
              message: t({ id: "app.promotion_tool.error_form" }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t({ id: "app.promotion_tool.description" })}
          name="description"
          rules={[
            {
              required: true,
              message: t({ id: "app.promotion_tool.error_form" }),
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="promotions"
          label={t({ id: "app.promotion_tool.promotions.name" })}
          rules={[{ required: true }]}
        >
          <Select
            mode="multiple"
            placeholder="Select a option and change input text above"
            allowClear
          >
            {dataPromotion.map((i, index) => (
              <Select.Option value={i.id} key={index}>
                {i.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 11, span: 24 }}>
          <Button type="primary" htmlType="submit">
            {t({ id: "app.promotion_tool.add" })}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default FormCreatePromotionGroup;
