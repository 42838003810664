import React from "react";
import { RouteComponentProps } from "@reach/router";

export default function DashboardScene(_: RouteComponentProps) {
  return (
    <div className="home-page">
      <div> DashboardScene</div>
    </div>
  );
}
