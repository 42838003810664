import { FC, useCallback, useEffect, useState } from "react";
import { Form, Input, Select, Button, message } from "antd";
import { apiUpdateCombo } from "../../services/combo.api";
import { useLocale } from "../../locales";
import { apiListProduction } from "../../services/production.api";
import { IProduction } from "../../types/production.type";
import { ICombo } from "../../types/combo.type";
import useApp from "../../context/app.context";

type ComboUpdateProps = {
  fetchData: () => void;
  data: ICombo | undefined;
};

const FormUpdateCombo: FC<ComboUpdateProps> = (props) => {
  const { t } = useLocale();
  const {
    stateAuth: { chanelId },
  } = useApp();
  const [dataProduct, setDataProduct] = useState<IProduction[]>([]);

  const fetchListProduction = useCallback(async () => {
    const res: IProduction[] = await apiListProduction(chanelId);
    if (res) {
      setDataProduct(res);
    }
  }, []);

  useEffect(() => {
    fetchListProduction();
  }, [fetchListProduction]);

  const onFinish = async (values: any) => {
    const res = await apiUpdateCombo(props.data?.id, values);
    if (res.status === 200) {
      message.success(t({ id: "app.promotion_tool.success" }));
      props.fetchData();
    } else {
      message.error(t({ id: "app.promotion_tool.error" }));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      layout="vertical"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      preserve={false}
    >
      <Form.Item
        label={t({ id: "app.promotion_tool.products.code" })}
        name="code"
        rules={[
          {
            required: true,
            message: t({ id: "app.promotion_tool.error_form" }),
          },
        ]}
        initialValue={props.data?.code}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t({ id: "app.promotion_tool.products.name" })}
        name="name"
        rules={[
          {
            required: true,
            message: t({ id: "app.promotion_tool.error_form" }),
          },
        ]}
        initialValue={props.data?.name}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t({ id: "app.promotion_tool.products.description" })}
        name="description"
        rules={[
          {
            required: true,
            message: t({ id: "app.promotion_tool.error_form" }),
          },
        ]}
        initialValue={props.data?.description}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="products"
        label="products"
        rules={[{ required: true }]}
        initialValue={props.data?.products}
      >
        <Select
          mode="multiple"
          placeholder="Select a option and change input text above"
          allowClear
          defaultValue={props.data?.products}
        >
          {dataProduct.map((i, index) => (
            <Select.Option value={i.id} key={index}>
              {i.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          {t({ id: "app.promotion_tool.add" })}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormUpdateCombo;
