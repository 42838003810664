import { FC, useCallback, useEffect, useState } from "react";
import { Form, Input, Select, Button, message } from "antd";
import { apiCreateCombo } from "../../services/combo.api";
import { useLocale } from "../../locales";
import { apiListProduction } from "../../services/production.api";
import { IProduction } from "../../types/production.type";
import useApp from "../../context/app.context";

type ComboCreateProps = {
  fetchData: () => void;
};

const FormCreateCombo: FC<ComboCreateProps> = (props) => {
  const { t } = useLocale();
  const {
    stateAuth: { chanelId },
  } = useApp();
  const [dataProduct, setDataProduct] = useState<IProduction[]>([]);

  const fetchListProduction = useCallback(async () => {
    const res: IProduction[] = await apiListProduction(chanelId);
    if (res) {
      setDataProduct(res);
    }
  }, []);

  useEffect(() => {
    fetchListProduction();
  }, [fetchListProduction]);

  const onFinish = async (values: any) => {
    const res = await apiCreateCombo(values);
    if (res.status === 201) {
      message.success(t({ id: "app.promotion_tool.success" }));
      props.fetchData();
    } else {
      message.error(t({ id: "app.promotion_tool.error" }));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div
        style={{
          padding: 6,
          backgroundColor: "#0057A5",
          marginBottom: 20,
          color: "white",
          fontSize: 16,
        }}
      >
        {t({ id: "app.promotion_tool.combos.info" })}
      </div>
      <Form
        name="basic"
        layout="horizontal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        preserve={false}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label={t({ id: "app.promotion_tool.products.code" })}
          name="code"
          rules={[
            {
              required: true,
              message: t({ id: "app.promotion_tool.error_form" }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t({ id: "app.promotion_tool.products.name" })}
          name="name"
          rules={[
            {
              required: true,
              message: t({ id: "app.promotion_tool.error_form" }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t({ id: "app.promotion_tool.products.description" })}
          name="description"
          rules={[
            {
              required: true,
              message: t({ id: "app.promotion_tool.error_form" }),
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="products"
          label={t({ id: "app.promotion_tool.promotions.name" })}
          rules={[{ required: true }]}
        >
          <Select
            mode="multiple"
            placeholder="Select a option and change input text above"
            allowClear
          >
            {dataProduct.map((i, index) => (
              <Select.Option value={i.id} key={index}>
                {i.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 11, span: 24 }}>
          <Button type="primary" htmlType="submit">
            {t({ id: "app.promotion_tool.add" })}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default FormCreateCombo;
