import { RouteComponentProps, useNavigate } from "@reach/router";
import { useICANID } from "@icanid/icanid-sdk-react";
import { useCallback } from "react";
import { Spin } from "antd";

export default function AuthPageScene(_: RouteComponentProps) {
  const navigate = useNavigate();
  //const { dispatchAuth } = useApp();
  const { isAuthenticated, getAccessTokenSilently } = useICANID();

  const getToken = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (token) {
      localStorage.setItem("token", token);
    }
  }, []);

  if (isAuthenticated === true) {
    getToken();
    // navigate("/promotion");
    // window.location.reload();
    window.location.href = "/promotion";
  }
  return (
    <div style={{ position: "absolute", top: "50%", left: 0, right: 0 }}>
      <Spin tip="Loading" size="large">
        <div className="content" />
      </Spin>
    </div>
  );
}
