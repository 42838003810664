import { FC, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { localeConfig } from "./locales";
import { ConfigProvider } from "antd";
import "./App.css";
import AppRoute from "./routes/AppRoute";
import enUS from "antd/es/locale/en_US";
import viVN from "antd/es/locale/vi_VN";
import moment from "moment";
import useApp from "./context/app.context";
import { ICANIDProvider, useICANID } from "@icanid/icanid-sdk-react";
import Config from "./config/config";

const App: FC = () => {
  const {
    stateAuth: { locale },
    dispatchAuth,
  } = useApp();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (locale === "en_US") {
      moment.locale("en");
    } else if (locale === "vi_VN") {
      moment.locale("vi-vn");
    }
    if (token) {
      // setToken(token);
      dispatchAuth({ type: "LOGIN" });
    } else {
      dispatchAuth({ type: "LOGOUT" });
      //navigate("/login");
    }
  }, [locale, dispatchAuth]);

  const getAntdLocale = () => {
    if (locale === "en_US") {
      return enUS;
    } else if (locale === "vi_VN") {
      return viVN;
    }
  };
  return (
    <ConfigProvider locale={getAntdLocale()} componentSize="middle">
      <IntlProvider
        locale={locale.split("_")[0]}
        messages={localeConfig[locale]}
      >
        <ICANIDProvider
          key={Config.DOMAIN_ID}
          domain={Config.DOMAIN_ID}
          authorizationParams={{
            redirect_uri: "https://promotion.dev.icankids.com.vn/auth",
            //redirect_uri: "http://localhost:3001/auth",
          }}
          useFormData={true}
          cacheLocation={"localstorage"}
          useRefreshTokens={true}
          clientId={Config.CLIENTID}
          clientSecret={Config.CLIENTSECRET}
          clientSecretMethod={"client_secret_post"}
        >
          <AppRoute />
        </ICANIDProvider>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default App;
