class AppURL {
  login() {
    return "login";
  }

  home() {
    return "/";
  }

  transaction() {
    return "transaction";
  }

  promotion() {
    return "promotion";
  }

  promotionGroup() {
    return "promotion-group";
  }

  combo() {
    return "combo";
  }

  production() {
    return "production";
  }
}

export default new AppURL();
