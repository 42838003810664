import React from "react";
import { getLocalStorageValue } from "../utils/utils";
import {
  AuthAction,
  AuthReducer,
  AuthState,
  initialStateAuth,
} from "../reducer/auth.reducer";

type AppContextProps = {
  stateAuth: AuthState;
  dispatchAuth: React.Dispatch<AuthAction>;
};

const AppContext = React.createContext<AppContextProps>({
  stateAuth: initialStateAuth,
  dispatchAuth: () => initialStateAuth,
});

export function AppProvider(props: React.PropsWithChildren<{}>) {
  const [stateAuth, dispatchAuth] = React.useReducer(
    AuthReducer,
    initialStateAuth
  );
  const token = getLocalStorageValue("token");
  React.useEffect(() => {
    if (token) return dispatchAuth({ type: "LOGIN" });
  }, [token]);

  return <AppContext.Provider value={{ stateAuth, dispatchAuth }} {...props} />;
}

export default function useApp() {
  return React.useContext(AppContext);
}
