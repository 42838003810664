import { navigate } from "@reach/router";
import axios from "axios";
import Config from "../config/config";
import AppURL from "../routes/AppURL";
import { IUser } from "../types/user.type";
import { getLocalStorageValue } from "../utils/utils";
import API from "./request";

const header = {
  Authorization: `${getLocalStorageValue("token")}`,
};

export const apiLogin = async (params: IUser) => {
  return await API.post("/promotion/api/auth/login/", params);
};

export const apiInfoUser = async () => {
  const res = await API.get("/promotion/api/auth/user/", { headers: header });
  if (res.status === 200) {
    return res.data;
  } else {
    localStorage.removeItem("token");
    navigate(AppURL.login());
  }
};

export const apiListMerchants = async (userId: string) => {
  const { data } = await axios.get(
    `${Config.API_BUS}/authorization/api/v1/external/users/${userId}/permissions`,
    {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }
  );
  const merchantIds: string[] = [];
  data.data.forEach((item: any) => {
    if (item?.resource === "merchants" && item?.action === "get") {
      item.conditions?.forEach((condition: any) => {
        const merchantId = condition?.merchant_id;
        if (merchantId) {
          merchantIds.push(merchantId);
        }
      });
    }
  });
  return merchantIds;
};

export const apiListChanels = async (merchantId: string) => {
  const { data } = await axios.get(
    `${Config.API_BUS}/business/api/v1/external/merchants/${merchantId}/channels`,
    {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }
  );

  return data;
};

export const apiLogOut = async () => {
  return await API.post("/promotion/api/auth/logout/");
};
