import React, { FC, useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Form,
  Select,
  message,
  Checkbox,
  InputNumber,
} from "antd";
import { FormInstance } from "antd/es/form";
import { IPromotionParams } from "../../types/promotion.type";
import { IProduction } from "../../types/production.type";
import { ICombo } from "../../types/combo.type";
import { apiListCombo } from "../../services/combo.api";
import { apiListProduction } from "../../services/production.api";
import {
  apiApprovePromotion,
  apiCreatePromotion,
  apiSubmitPromotion,
} from "../../services/promotion.api";
import { useLocale } from "../../locales";

import styles from "./styles.module.scss";
import useApp from "../../context/app.context";

type PromotionCreateProps = {
  fetchData: () => void;
};

const CreateFormPromotion: FC<PromotionCreateProps> = (props) => {
  const { t } = useLocale();
  const {
    stateAuth: { chanelId },
  } = useApp();
  const formRef = React.createRef<FormInstance>();
  const [forms, setForms] = useState<Partial<IPromotionParams>>({
    internal: false,
    offer: false,
  });
  const [dataProduct, setDataProduct] = useState<IProduction[]>([]);
  const [dataCombo, setDataCombo] = useState<ICombo[]>([]);
  const [codePrefix, setCodePrefix] = useState("");
  const [codePattern, setCodePattern] = useState("");
  const [codePostfix, setCodePostfix] = useState("");
  const [valueProduct, setValueProduct] = useState("");
  const [usageLimit, setUsageLimit] = useState(0);
  const [usageLimitProduct, setUsageLimitProduct] = useState(0);
  const [ruleProductType, setRuleProductType] = useState("");

  const fetchListCombo = useCallback(async () => {
    const res: ICombo[] = await apiListCombo();
    if (res) {
      setDataCombo(res);
    }
  }, []);

  const fetchListProduction = useCallback(async () => {
    const res: IProduction[] = await apiListProduction(chanelId);
    if (res) {
      setDataProduct(res);
    }
  }, []);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setForms({
      ...forms,
      [name]: value,
    });
  };

  const submitPromotion = async (id: string) => {
    const res = await apiSubmitPromotion(id, chanelId);
    if (res.data.status === "SU") {
      await approvePromotion(id);
    } else {
      message.error({ content: "Error" });
    }
  };

  const approvePromotion = async (id: string) => {
    await apiApprovePromotion(id, null, chanelId)
      .then(async (res) => {
        props.fetchData();
        //console.log(res);
      })
      .catch(() => {
        message.error({ content: "Error!" });
      });
  };

  useEffect(() => {
    fetchListProduction();
    fetchListCombo();
  }, [fetchListProduction, fetchListCombo]);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values: any) => {
    const res = await apiCreatePromotion(
      {
        ...forms,
        kind: "NO",
        // internal: false,
        // code_generates_date: moment().format(),
        code_generation_formula: {
          code_prefix: codePrefix,
          code_pattern: codePattern,
          code_postfix: codePostfix,
          character_set: "alphanumeric",
        },
        eligible_rules: [
          {
            name: "products",
            type: ruleProductType,
            value: valueProduct,
          },
          {
            name: "per_user_usage_limit",
            value: usageLimit,
          },
          {
            name: "usage_limit_per_product",
            value: usageLimitProduct,
          },
        ],
      },
      chanelId
    );
    if (res.status === 201) {
      formRef.current?.resetFields();
      submitPromotion(res.data.id);
      message.success(t({ id: "app.promotion_tool.success" }));
    } else {
      message.error(t({ id: "app.promotion_tool.error" }));
    }
  };

  return (
    <>
      <Form
        layout="horizontal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        ref={formRef}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <div>
          <div className={styles.titlePromotion}>
            {t({ id: "app.promotion_tool.form_create.promo_info" })}
          </div>
          <Col span={12}>
            <Form.Item
              label={t({ id: "app.promotion_tool.promotions.internal" })}
              name="internal"
              // rules={[
              //   {
              //     required: true,
              //     message: t({ id: "app.promotion_tool.error_form" }),
              //   },
              // ]}
            >
              <Checkbox
                defaultChecked={false}
                name="internal"
                onChange={(e: any) => {
                  setForms({ ...forms, internal: e.target.checked });
                }}
                value={forms.internal}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t({ id: "app.promotion_tool.promotions.code" })}
              name="code"
              rules={[
                {
                  required: true,
                  message: t({ id: "app.promotion_tool.error_form" }),
                },
              ]}
            >
              <Input
                name="code"
                onChange={handleInputChange}
                value={forms.code}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t({ id: "app.promotion_tool.promotions.name" })}
              name="name"
              rules={[
                {
                  required: true,
                  message: t({ id: "app.promotion_tool.error_form" }),
                },
              ]}
            >
              <Input
                name="name"
                onChange={handleInputChange}
                value={forms.name}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t({ id: "app.promotion_tool.promotions.description" })}
              name="description"
              rules={[
                {
                  required: true,
                  message: t({ id: "app.promotion_tool.error_form" }),
                },
              ]}
            >
              <Input.TextArea
                name="description"
                onChange={handleInputChange}
                value={forms.description}
              />
            </Form.Item>
          </Col>
        </div>
        <div>
          <div className={styles.titlePromotion}>
            {t({ id: "app.promotion_tool.form_create.promo_value" })}
          </div>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={t({
                  id: "app.promotion_tool.promotions.redemption_type",
                })}
                tooltip={
                  <>
                    <span>coupon: mã xài nhiều lần</span>
                    <br />
                    <span>voucher: mã xài 1 lần</span>
                  </>
                }
                name="redemption_type"
                rules={[
                  {
                    required: true,
                    message: t({ id: "app.promotion_tool.error_form" }),
                  },
                ]}
              >
                <Select
                  onChange={(value: string) =>
                    setForms({ ...forms, redemption_type: value })
                  }
                >
                  <Select.Option value="CO">coupon</Select.Option>
                  <Select.Option value="VO">voucher</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t({
                  id: "app.promotion_tool.promotions.redemption_value",
                })}
                name="redemption_value"
                rules={[
                  {
                    required: true,
                    message: t({ id: "app.promotion_tool.error_form" }),
                  },
                ]}
              >
                <Select
                  onChange={(value: string) =>
                    setForms({ ...forms, redemption_value: value })
                  }
                >
                  <Select.Option value="DI">Giảm giá</Select.Option>
                  <Select.Option value="GI">Quà tặng</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t({ id: "app.promotion_tool.promotions.code_duration" })}
                name="code_duration"
                rules={[
                  {
                    required: true,
                    message: t({ id: "app.promotion_tool.error_form" }),
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  name="code_duration"
                  style={{ width: "100%" }}
                  type="number"
                  //suffix="Ngày"
                  onChange={
                    (value: any) => setForms({ ...forms, code_duration: value })
                    //console.log(value)
                  }
                  value={forms.code_duration}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t({
                  id: "app.promotion_tool.promotions.amount_of_codes",
                })}
                name="amount_of_codes"
                rules={[
                  {
                    required: true,
                    message: t({ id: "app.promotion_tool.error_form" }),
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  min={1}
                  name="amount_of_codes"
                  style={{ width: "100%" }}
                  //onChange={handleInputChange}
                  onChange={(value: any) =>
                    setForms({ ...forms, amount_of_codes: value })
                  }
                />
              </Form.Item>
            </Col>
            {forms.redemption_value === "GI" && (
              <>
                <Col span={12}>
                  <Form.Item
                    label={t({ id: "app.promotion_tool.promotions.gift_type" })}
                    name="gift_type"
                    rules={[
                      {
                        required: true,
                        message: t({ id: "app.promotion_tool.error_form" }),
                      },
                    ]}
                  >
                    <Select
                      onChange={(value: string) => {
                        setForms({ ...forms, gift_type: value });
                      }}
                    >
                      <Select.Option value="PR">Product</Select.Option>
                      <Select.Option value="CO">Combo</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {forms.gift_type === "PR" && (
                  <Col span={12}>
                    <Form.Item
                      label={t({
                        id: "app.promotion_tool.promotions.promotion_product",
                      })}
                      name="promotion_product"
                      rules={[
                        {
                          required: true,
                          message: t({ id: "app.promotion_tool.error_form" }),
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        onChange={(value: string) => {
                          setForms({ ...forms, promotion_product: value });
                        }}
                      >
                        {dataProduct.map((i, index) => (
                          <Select.Option value={i.id} key={index}>
                            {i.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}

                {forms.gift_type === "CO" && (
                  <Col span={12}>
                    <Form.Item
                      label={t({
                        id: "app.promotion_tool.promotions.promotion_combo",
                      })}
                      name="promotion_combo"
                      rules={[
                        {
                          required: true,
                          message: t({ id: "app.promotion_tool.error_form" }),
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        onChange={(value: string) => {
                          setForms({ ...forms, promotion_combo: value });
                        }}
                      >
                        {dataCombo.map((i, index) => (
                          <Select.Option value={i.id} key={index}>
                            {i.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              </>
            )}
            {forms.redemption_value === "DI" && (
              <>
                <Col span={12}>
                  <Form.Item
                    label={t({
                      id: "app.promotion_tool.promotions.discount_type",
                    })}
                    name="discount_type"
                    rules={[
                      {
                        required: true,
                        message: t({ id: "app.promotion_tool.error_form" }),
                      },
                    ]}
                  >
                    <Select
                      onChange={(value: string) => {
                        setForms({ ...forms, discount_type: value });
                      }}
                    >
                      <Select.Option value="FL">Tiền</Select.Option>
                      <Select.Option value="PE">%</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {forms.discount_type === "PE" && (
                  <Col span={12}>
                    <Form.Item
                      label={t({
                        id: "app.promotion_tool.promotions.max_flat_discount_value",
                      })}
                      name="max_flat_discount_value"
                      rules={[
                        {
                          required: true,
                          message: t({ id: "app.promotion_tool.error_form" }),
                        },
                      ]}
                    >
                      <InputNumber
                        type="number"
                        //onChange={handleInputChange}
                        //suffix="VND"
                        onChange={(value: any) =>
                          setForms({ ...forms, max_flat_discount_value: value })
                        }
                        min={1}
                        name="max_flat_discount_value"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                )}

                <Col span={12}>
                  <Form.Item
                    label={t({
                      id: "app.promotion_tool.promotions.discount_value",
                    })}
                    name="discount_value"
                    rules={[
                      {
                        required: true,
                        message: t({ id: "app.promotion_tool.error_form" }),
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      //onChange={handleInputChange}
                      //suffix={forms.discount_type === "PE" ? "%" : "VND"}
                      style={{ width: "100%" }}
                      min={1}
                      name="discount_value"
                      onChange={(value: any) =>
                        setForms({ ...forms, discount_value: value })
                      }
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </div>
        <div>
          <div className={styles.titlePromotion}>Eligible rules</div>
          <Col span={12}>
            <Form.Item
              label={t({ id: "app.promotion_tool.promotions.offer" })}
              name="offer"
              // rules={[
              //   {
              //     required: true,
              //     message: t({ id: "app.promotion_tool.error_form" }),
              //   },
              // ]}
            >
              <Checkbox
                defaultChecked={false}
                name="offer"
                onChange={(e: any) => {
                  setForms({ ...forms, offer: e.target.checked });
                }}
                value={forms.internal}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t({ id: "app.promotion_tool.products.name" })}
              name="value_product"
            >
              <Select
                mode="multiple"
                allowClear
                onChange={(value: string) => {
                  setValueProduct(value);
                }}
              >
                <Select.Option value="">All</Select.Option>
                {dataProduct.map((i, index) => (
                  <Select.Option value={i.id} key={index}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t({ id: "app.promotion_tool.promotions.type" })}
              name="type"
            >
              <Select
                allowClear
                onChange={(value: string) => {
                  setRuleProductType(value);
                }}
              >
                <Select.Option value="all">All</Select.Option>
                <Select.Option value="subset">Subset</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t({
                id: "app.promotion_tool.promotions.promotion_per_user_usage_limit",
              })}
              name="per_user_usage_limit"
            >
              <InputNumber
                type="number"
                onChange={(e: any) => {
                  setUsageLimit(e);
                }}
                min={-1}
                name="per_user_usage_limit"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t({
                id: "app.promotion_tool.promotions.promotion_usage_limit_product",
              })}
              name="usage_limit_per_product"
            >
              <InputNumber
                type="number"
                onChange={(e: any) => {
                  setUsageLimitProduct(e);
                }}
                min={-1}
                name="per_user_usage_limit"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </div>
        <div>
          <div className={styles.titlePromotion}>
            {t({ id: "app.promotion_tool.form_create.setup_code_promo" })}
          </div>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={t({ id: "app.promotion_tool.promotions.code_prefix" })}
                name="code_prefix"
              >
                <Input
                  name="code_prefix"
                  onChange={(e) => {
                    setCodePrefix(e.target.value);
                  }}
                  value={codePrefix}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                tooltip="Nhập # để tự random"
                label={t({ id: "app.promotion_tool.promotions.code_pattern" })}
                name="code_pattern"
                rules={[
                  {
                    required: true,
                    message: t({ id: "app.promotion_tool.error_form" }),
                  },
                ]}
              >
                <Input
                  name="code_pattern"
                  onChange={(e) => {
                    setCodePattern(e.target.value);
                  }}
                  value={codePattern}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t({ id: "app.promotion_tool.promotions.code_postfix" })}
                name="code_postfix"
              >
                <Input
                  name="code_postfix"
                  onChange={(e) => {
                    setCodePostfix(e.target.value);
                  }}
                  value={codePostfix}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t({
                  id: "app.promotion_tool.promotions.code_enerates_date",
                })}
                name="code_generates_date"
                rules={[
                  {
                    required: true,
                    message: t({ id: "app.promotion_tool.error_form" }),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(date, dateString) => {
                    setForms({ ...forms, code_generates_date: date });
                  }}
                  name="code_generates_date"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div>
          <div className={styles.titlePromotion}>
            {t({ id: "app.promotion_tool.form_create.setup_date" })}
          </div>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={t({ id: "app.promotion_tool.promotions.expected_date" })}
                name="expected_date"
                rules={[
                  {
                    required: true,
                    message: t({ id: "app.promotion_tool.error_form" }),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(date, dateString) => {
                    setForms({ ...forms, expected_date: date });
                  }}
                  name="expected_date"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t({ id: "app.promotion_tool.promotions.expires_date" })}
                name="expires_date"
                rules={[
                  {
                    required: true,
                    message: t({ id: "app.promotion_tool.error_form" }),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(date, dateString) => {
                    setForms({ ...forms, expires_date: date });
                  }}
                  name="expires_date"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t({ id: "app.promotion_tool.promotions.expected_hour" })}
                name="expected_hour"
                rules={[
                  {
                    required: true,
                    message: t({ id: "app.promotion_tool.error_form" }),
                  },
                ]}
              >
                <DatePicker
                  picker={"time"}
                  style={{ width: "100%" }}
                  onChange={(date, dateString) => {
                    setForms({ ...forms, expected_hour: dateString });
                  }}
                  name="expected_hour"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t({ id: "app.promotion_tool.promotions.expires_hour" })}
                name="expires_hour"
                rules={[
                  {
                    required: true,
                    message: t({ id: "app.promotion_tool.error_form" }),
                  },
                ]}
              >
                <DatePicker
                  picker={"time"}
                  style={{ width: "100%" }}
                  onChange={(date, dateString) => {
                    setForms({ ...forms, expires_hour: dateString });
                  }}
                  name="expires_hour"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
          <Button type="primary" htmlType="submit">
            {t({ id: "app.promotion_tool.add" })}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CreateFormPromotion;
