import axios from "axios";
import { navigate } from "@reach/router";
import { message, Modal } from "antd";
import Config from "../config/config";
import AppURL from "../routes/AppURL";

axios.defaults.baseURL = Config.API_URL;

axios.defaults.headers = {
  //"x-api-channel-id": localStorage.getItem("chanelId"),
  "x-api-merchant-id": localStorage.getItem("merchantId"),
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error.response.status) {
      case 401:
        Modal.error({
          title: "Error !",
          content: error.response.data.detail,
          onOk: () => {
            localStorage.removeItem("token");
            navigate(AppURL.login());
          },
        });
        break;
      case 404:
      case 403:
        navigate("/");
        break;
      case 500:
        message.error({ content: "500 Internal Server Error" });
        break;
    }
    return Promise.reject(error.response);
  }
);

export function setToken(token: string | null) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Token ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export default axios;
