import { Button, Card, Table, Modal, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, Link } from "@reach/router";
import { apiDeleteCombo, apiListCombo } from "../../../services/combo.api";
import { useLocale } from "../../../locales";
import { ICombo } from "../../../types/combo.type";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import FormCreateCombo from "../../../components/HFormCreateCombo";
import FormUpdateCombo from "../../../components/HFormUpdateCombo";
import moment from "moment";
import { tableColumnTextFilterConfig } from "../../../utils/filterTable";

type Data = {
  key: string;
  name: string;
};

export default function ComboScene(_: RouteComponentProps) {
  const { t } = useLocale();
  const [data, setData] = useState<ICombo[]>([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalUpdate, setIsModalUpdate] = useState(false);
  const [dataUpdate, setDataUpdate] = useState<ICombo>();

  const fetchListCombo = useCallback(async () => {
    setLoading(true);
    const res: ICombo[] = await apiListCombo();
    if (res) {
      setData(res);
      setIsModalVisible(false);
      setIsModalUpdate(false);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchListCombo();
  }, [fetchListCombo]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelUpdate = () => {
    setIsModalUpdate(false);
  };

  const deleteCombo = async (id: string) => {
    const res = await apiDeleteCombo(id);
    if (res.status === 204) {
      message.success("Delete success!");
      fetchListCombo();
    }
  };

  const columns: any = [
    {
      fixed: "left",
      title: t({ id: "app.promotion_tool.products.name" }),
      key: "name",
      render: (text: ICombo) => (
        <Link to={`/combos/${text.id}`}>{text.name}</Link>
      ),
      ...tableColumnTextFilterConfig<Data>(),
      onFilter: (value: any, record: any) => {
        return record.name
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
    {
      title: t({ id: "app.promotion_tool.products.code" }),
      dataIndex: "code",
      key: "code",
    },
    {
      title: t({ id: "app.promotion_tool.products.description" }),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t({ id: "app.promotion_tool.products.created_date" }),
      key: "created_date",
      render: (record: ICombo) => {
        return moment(record.created_date).format("L");
      },
    },
    {
      title: t({ id: "app.promotion_tool.products.updated_date" }),
      key: "updated_date",
      render: (record: ICombo) => {
        return moment(record.updated_date).format("L");
      },
    },
    {
      title: t({ id: "app.promotion_tool.action" }),
      key: "action",
      render: (text: any, record: ICombo) => (
        <div>
          <Button
            onClick={() => {
              setIsModalUpdate(!isModalUpdate);
              setDataUpdate(record);
            }}
            type="primary"
            icon={<EditOutlined />}
          />
          <Button
            style={{ marginLeft: "10px" }}
            danger
            icon={<DeleteOutlined />}
            onClick={() => deleteCombo(record.id)}
          />
        </div>
      ),
    },
  ];
  return (
    <div>
      <Card
        title={t({ id: "app.promotion_tool.combo.title" })}
        extra={
          <Button
            type="primary"
            onClick={() => {
              setIsModalVisible(!isModalVisible);
            }}
          >
            {t({ id: "app.promotion_tool.add" })}
          </Button>
        }
      >
        {/* <Row gutter={16}>
          <Col span={12}>
            <span>Filter</span>
            <Input />
          </Col>
          <Col span={12}>
            <span>Filter on</span>
          </Col>
        </Row>
        <br /> */}
        <Table
          loading={loading}
          scroll={{ x: 1500 }}
          dataSource={data}
          columns={columns}
        />
      </Card>
      <Modal
        title={t({ id: "app.promotion_tool.combo.add_combo" })}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        width={900}
      >
        <FormCreateCombo fetchData={fetchListCombo} />
      </Modal>
      <Modal
        title={t({ id: "app.promotion_tool.products.add_production" })}
        visible={isModalUpdate}
        footer={null}
        onCancel={handleCancelUpdate}
      >
        <FormUpdateCombo fetchData={fetchListCombo} data={dataUpdate} />
      </Modal>
    </div>
  );
}
