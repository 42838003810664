import { Dropdown, Layout, Select, Avatar, Menu, Button, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { navigate } from "@reach/router";
import styles from "./style.module.scss";
import useApp from "../../context/app.context";
import {
  apiListChanels,
  apiListMerchants,
  apiLogOut,
} from "../../services/user.api";
import AppURL from "../../routes/AppURL";
import { useICANID } from "@icanid/icanid-sdk-react";
import { useEffect, useState } from "react";

const { Header } = Layout;

const HHeader = () => {
  const {
    stateAuth: { locale, isAuthenticated },
    dispatchAuth,
  } = useApp();
  const { user } = useICANID();
  const [options, setOptions] = useState<any>();

  const getListMerchant = async () => {
    const merchantIds = await apiListMerchants(user?.muid);
    if (merchantIds) {
      localStorage.setItem("merchantId", merchantIds[0]);
    }
    const res = await apiListChanels(merchantIds[0]);
    if (res.message === "success") {
      localStorage.setItem("chanelId", res?.data[0]?.id);
      const otps: any = [{label: 'All', value: ''}];
      res.data.map((i: any) =>
        otps.push({
          label: i.name,
          value: i.id,
        })
      );
      setOptions(otps);
    }
  };
  const handleChange = (value: any) => {
    dispatchAuth({ locale: value, type: "GET_LOCALE" });
    localStorage.setItem("locale", value);
  };

  useEffect(() => {
    getListMerchant();
  }, [user]);

  const handleLogOut = async () => {
    await apiLogOut()
      .then(() => {
        localStorage.removeItem("token");
        navigate(AppURL.login());
      })
      .catch(() => {
        message.error({ content: "Logout Error!" });
      });
  };
  console.log(options);

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Button
          onClick={() => {
            handleLogOut();
          }}
        >
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <Header
      className="site-layout-background"
      style={{ padding: 0, backgroundColor: "white" }}
    >
      <div className={styles.myHeader}>
        <div>
          <Select
            defaultValue={locale}
            style={{ width: 120 }}
            onChange={handleChange}
          >
            <Select.Option value="vi_VN">Vietnamese</Select.Option>
            <Select.Option value="en_US">English</Select.Option>
          </Select>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Select
            //defaultValue={localStorage.getItem("chanelId")}
            placeholder="Chọn chanel"
            style={{ marginRight: 20 }}
            onChange={(e) => {
              dispatchAuth({ chanelId: e, type: "SET_CHANELID" });
              localStorage.setItem("chanelId", e);
            }}
            options={options}
          />
          {isAuthenticated ? (
            <Dropdown overlay={menu} trigger={["click"]}>
              <a
                href="/#"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Avatar icon={<UserOutlined />} />
              </a>
            </Dropdown>
          ) : null}
        </div>
      </div>
    </Header>
  );
};

export default HHeader;
