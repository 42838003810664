export const viVN_sideBar = {
    'app.sidebar.dashboard': 'Tổng quan',
    'app.sidebar.transaction': 'Giao dịch',
    'app.sidebar.manage_sms': 'Quản lý tin nhắn',
    'app.sidebar.promotion_tool': 'Công cụ khuyến mại',
    'app.sidebar.promotion_tool.promotion': 'Khuyến mại',
    'app.sidebar.promotion_tool.promotion_group': 'Nhóm khuyến mại',
    'app.sidebar.promotion_tool.combo': 'Gói',
    'app.sidebar.promotion_tool.production': 'Sản phẩm',
    'app.sidebar.manage_sms.send_sms': 'Gửi tin nhắn',
    'app.sidebar.manage_sms.manage_campaign': 'Quản lý chiến lược',
    'app.sidebar.manage_sms.manage_sms_sample': 'Quản lý tin nhắn mẫu',
    'app.sidebar.manage_sms.flow_sms': 'Theo dõi trạng thái tin nhắn',
    'app.sidebar.manage_sms.change_phone_number': 'Thay đổi số điện thoại',
    'app.sidebar.manage_sms.check_phone_number': 'Kiểm tra số điên thoại',
    'app.sidebar.manage_sms.sms_toast': 'Gửi tin nhắn chúc mừng',
    'app.sidebar.manage_sms.report_sms': 'Báo cáo tin nhắn',
    'app.sidebar.manage_sms.report_daily': 'Báo cáo hằng ngày',
    'app.sidebar.manage_sms.report_monthly': 'Báo cáo hằng tháng',
    'app.sidebar.manage_sms.report_campain': 'Báo cáo chiến dịch',
    'app.sidebar.manage_sms.export_sms': 'Xuất tin nhắn',
  };