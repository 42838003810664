// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_titlePromotion__3Sukl {\n  width: 100%;\n  background-color: #0057a5;\n  color: white;\n  font-weight: bold;\n  padding: 8px;\n  margin-bottom: 20px;\n}", "",{"version":3,"sources":["webpack://src/components/HFormCreatePromotion/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;EACA,mBAAA;AACF","sourcesContent":[".titlePromotion {\n  width: 100%;\n  background-color: #0057a5;\n  color: white;\n  font-weight: bold;\n  padding: 8px;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titlePromotion": "styles_titlePromotion__3Sukl"
};
export default ___CSS_LOADER_EXPORT___;
