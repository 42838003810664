export const enUS_Promotion = {
  "app.promotion_tool.promotions.title": "List Promotion",
  "app.promotion_tool.promotions.code": "Code promotion",
  "app.promotion_tool.promotions.name": "Name promotion",
  "app.promotion_tool.promotions.id_product": "ID product",
  "app.promotion_tool.promotions.type_promotion": "Promotion type",
  "app.promotion_tool.promotions.redemption_type": "Redemption type",
  "app.promotion_tool.promotions.expires_date": "Expires date",
  "app.promotion_tool.promotions.expires_hour": "Expires hour",
  "app.promotion_tool.promotions.expected_hour": "Expected hour",
  "app.promotion_tool.promotions.expected_date": "Expected Date",
  "app.promotion_tool.promotions.discount_type": "Discount Type",
  "app.promotion_tool.promotions.redemption_value": "Redemption Value",
  "app.promotion_tool.promotions.code_duration": "Code duration",
  "app.promotion_tool.promotions.amount_of_codes": "Amount of codes",
  "app.promotion_tool.promotions.max_flat_discount_value":
    "Max flat discount value",
  "app.promotion_tool.promotions.flat_discount_value": "Flat discount value",
  "app.promotion_tool.promotions.gift_type": "Gift Type",
  "app.promotion_tool.promotions.status": "Status",
  "app.promotion_tool.promotions.description": "Description",
  "app.promotion_tool.promotions.modal.title": "Add promotion",
  "app.promotion_tool.promotions.modalUpdate.title": "Update promotion",
  "app.promotion_tool.promotions.discount_value": "Discount value",
  "app.promotion_tool.promotions.promotion_combo": "Promotion combo",
  "app.promotion_tool.promotions.promotion_product": "Promotion product",
  "app.promotion_tool.promotions.starts_date": "Starts date",
  "app.promotion_tool.promotions.state": "State",
  "app.promotion_tool.promotions.updated_reason": "Update reason",

  "app.promotion_tool.form_create.promo_info": "Infomation promo",
  "app.promotion_tool.form_create.promo_value": "Value promo",
  "app.promotion_tool.form_create.setup_code_promo": "Setup code promo",
  "app.promotion_tool.form_create.setup_date": "Setup date",
  "app.promotion_tool.promotions.internal": "Internal",

  "app.promotion_tool.action": "Action",
  "app.promotion_tool.name": "Name",
  "app.promotion_tool.code": "Code",
  "app.promotion_tool.description": "Description",
  "app.promotion_tool.created_date": "Created date",
  "app.promotion_tool.updated_date": "Updated date",
  "app.promotion_tool.products": "Products",
  "app.promotion_tool.search": "Search",
  "app.promotion_tool.add": "Add",
  "app.promotion_tool.error_form": "Please input your field!",
  "app.promotion_tool.success": "Success!",
  "app.promotion_tool.error": "Error!",

  "app.promotion_tool.products.title": "List production",
  "app.promotion_tool.products.add_production": "Add production",
  "app.promotion_tool.products.code": "Product code",
  "app.promotion_tool.products.name": "Product Name",
  "app.promotion_tool.products.description": "Description",
  "app.promotion_tool.products.created_date": "Created date",
  "app.promotion_tool.products.updated_date": "Updated date",
  "app.promotion_tool.products.info": "Infomation Product",

  "app.promotion_tool.combo.title": "List combo",
  "app.promotion_tool.combo.add_combo": "Add combo",
  "app.promotion_tool.combos.code": "Product code",
  "app.promotion_tool.combos.name": "Product Name",
  "app.promotion_tool.combos.info": "Infomation combo ",
  "app.promotion_tool.promotions.promotion_per_user_usage_limit":
    "Per user usage limit",
  "app.promotion_tool.promotions.promotion_usage_limit_product":
    "Usage limit per product",
  "app.promotion_tool.promotions.offer": "Offer",
  "app.promotion_tool.promotions.redemption_component": "Redemption Component",

  "app.promotion_tool.promotions.code_prefix": "Code prefix",
  "app.promotion_tool.promotions.code_pattern": "Code pattern",
  "app.promotion_tool.promotions.code_postfix": "Code postfix",
  "app.promotion_tool.promotions.code_enerates_date": "Code generates date",

  "app.promotion_tool.promotions.promotion_product_type": "Product type",
  "app.promotion_tool.promotions.type": "Type",

  "app.promotion_tool.promotions_group.title": "Promotion Groups",
  "app.promotion_tool.promotions_group.add_promotion_group":
    "Add Promotion Groups",
};
