// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapperLogin__23cax {\n  width: 500px;\n  margin: 10em auto;\n}", "",{"version":3,"sources":["webpack://src/scenes/login/style.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,iBAAA;AACJ","sourcesContent":[".wrapperLogin{\n    width: 500px;\n    margin: 10em auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperLogin": "style_wrapperLogin__23cax"
};
export default ___CSS_LOADER_EXPORT___;
